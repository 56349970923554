<template>
  <footer>
    <div class="footer-section">
      <div class="center-box">
        <div class="logo-img"><img src="../assets/image/logo-white.png" alt=""></div>
        <div class="msg-box">
          <div class="line"></div>
          <div class="message-box">
            <div class="title">与我们沟通<span class="title-en">/CONTACT US</span></div>
            <div class="text">公司地址：高新区科技路48号创业广场</div>
            <div class="text">联系我们：postmaster@lcamtech.com</div>
            <div class="text">客服电话：400-888-3918</div>
          </div>
        </div>
        
        <div class="QRCode-box">
          <img src="../assets/image/QRcode.png" alt="">
          <div class="text">微信关注我们</div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="center-box">
        Copyright © 2017 - 2020 陕西领创精准医疗科技有限公司 <a href="https://beian.miit.gov.cn" target="_blank">陕ICP备17019378号</a> All Rights Reserved.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Myfooter',
}
</script>
<style lang="less" scoped>
@import '../assets/less/footer.less';
</style>