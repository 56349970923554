<template>
 <div class="new-content" >
   <div class="detail-title">{{title}}</div>
  <div class="datail-subtitle">
    <div class="subtitle-content">来源：{{author}}</div>
    <div class="subtitle-content">日期：{{createTime}}</div>
  </div>
   <div v-html="newContent" class="datail-content"></div>
 </div>
</template>
<script>
import {
    getNewMsg,
    // getMatchPlan
  } from '../requestModule/getNewContent.js';
  import {
    newsList
  } from '../../public/newDetails.js'
  export default {
    name: 'newDetails',
    data() {
      return {
        newContent: '',
        newId: this.$route.query.id,
        title: '',
        author: '',
        createTime: '',
      }
    },
    created(){
      this.getData();
    },
    methods: {
      getData(){
        // getNewMsg(this.pageIndex,this.pageNub).then((result) => {
        //   let data = result.data;
          for (let item of newsList) {
            if(item.id == this.newId){
              this.newContent = item.content;
              this.title = item.title;
              this.author = item.author;
              this.createTime = item.createTime;
              return;
            }
          }
      //   }).catch((err) => {
      //   });
      },
    }
  }
</script>
<style lang="less" >
  @import '../assets/less/newDetail.less';
</style>