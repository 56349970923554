<template>
  <header :class="[headerTop,colorSty]">
    <div class="header-center">
      <router-link :to="{path:'/'}">
        <div class="left-logo" :class="routerName == 'home' ? 'hover-text' : ''">
          <img src="../assets/image/logo.png" >
        </div>
      </router-link>
      <div class="center-nav">
        <ul class="navList">
          <router-link :to="{path:'/'}">
           <li :class="routerName == 'home' ? 'hover-text' : ''">首页</li>
          </router-link>
          <router-link :to="{path:'/product'}">
           <li :class="routerName == 'product' ? 'hover-text' : ''">医学大脑</li>
          </router-link>
          <!-- <router-link :to="{path:'/prescription'}">
           <li :class="routerName == 'prescription' ? 'hover-text' : ''">慢病管理</li>
          </router-link> -->
          <router-link :to="{path:'/news'}">
            <li :class="routerName == 'newList' || routerName == 'newDetails' ? 'hover-text' : ''">关于企业</li>
          </router-link>
            <li @mouseover="onItem(3)" @mouseout="onItem()" :class="onblock == 3 ? 'hover-text' : ''">关注公众号
            <img src="../assets/image/QRcode-hover.png" :class=" onblock == 3 ? 'no-hide' : 'hide'" alt="" class="QRCode-image">
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Myheader',
  data () {
    return {
      onblock: null,
      headerTop: '',
      oldScrollTop: 0,
      colorSty: '',
    }
  },
  computed:{
    routerName() {
      return this.$store.getters.getRouterName;
    },
  },
  methods: {
    onItem: function (key) {
      this.onblock = key;
    },
    handleScroll: function(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      this.headerTop = scrollTop <= this.oldScrollTop || this.oldScrollTop == 0? 'active' : 'active before';
      this.colorSty = scrollTop >= 600 ? 'item-shadow' : '';
      if(scrollTop == 0){
        this.headerTop = '';
        this.colorSty = 'item-shadow';
      }
      if(scrollTop + windowHeight == scrollHeight){
        this.headerTop = 'active';
        this.colorSty = 'item-shadow';
      }
      this.oldScrollTop = scrollTop;
    },
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
}
</script>
<style lang="less" scoped>
@import '../assets/less/header.less';
</style>