import Axios from 'axios';
import store from './store';
// 开发环境
// const develop = 'https://dw.lengconginfo.com';
// 测试环境
// const test = 'https://dw.lengconginfo.cn';
// 本地环境
// const local = 'http://192.168.4.56:9110';
// 生产环境
// const product = 'https://dw.lcamtech.com';
const staticUrl = './';

// 设置axios实例基础信息
const service = Axios.create({
    baseURL: staticUrl,
    timeout: 1500
})

service.interceptors.response.use(
    function (response) {
        return Promise.resolve(response)
    },
    function (error) {
        // 请求错误，向state commit这个状态变化
        const httpError = {
            hasError: true,
            status: error.response.status,
            statusText: error.response.statusText
        }
        store.commit('setNewContent', httpError)
        return Promise.reject(error)
    }
)

export default service;
