import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      routerName: '',
  },
  getters: {
    getRouterName(state) {
        return state.routerName
    },
  },
  mutations: {
    setRouterName (state, payload) {
      // console.log(state);
        state.routerName = payload
    },
  },
  actions: {
    setRouterName (context, payload) {
        context.commit('setRouterName', payload)
    },
     
  }
})
