<template>
  <div class="content-box" id="product">
    <div class="banner-box">
      <div class="center-box">
        <div class="text-box">
          <div class="title">领创大脑APP</div>
          <div class="text">国家首个AI赋能神经内科领域智能辅助诊疗应用——小神</div>
          <div class="download-btn">扫码下载
            <div  class="download-img">
              <img src="../assets/image/product_QRcode.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- app介绍 -->
    <div class="app-present">
      <div class="center-box">
        <div class="first-title">领创医学大脑APP</div>
        <div class="second-text">神经内科医生的诊断平台</div>
        <div class="present-box">
          <div class="present-item skill item-1">
            <div class="present-icon"></div>
            <div class="present-title">学术成长</div>
            <div class="present-line"></div>
            <div class="present-text">丰富的神经内科医学内容<br>临床病例记录及智能诊疗一站式服务<br>专家经验、病例分享等帮助基层神内医生<br>快速成长</div>
            <div class="btn-box btn-2">
              <div class="present-btn-box ">
                <div class="present-btn">快捷诊断</div>
                <div class="present-btn">精品资讯</div>
              </div>
            </div>
          </div>
          <div class="present-item diagnosis item-2">
            <div class="present-icon"></div>
            <div class="present-title">精准诊断</div>
            <div class="present-line"></div>
            <div class="present-text">辅助诊疗帮助神内医生快速诊断<br>床病例记录及智能诊疗一站式服务</div>
            <div class="btn-box  btn-4">
              <div class="present-btn-box ">
                <div class="present-btn">脑膜炎</div>
                <div class="present-btn">眩晕</div>
              </div>
              <div class="present-btn-box ">
                <div class="present-btn">头痛</div>
                <div class="present-btn">更多病种</div>
              </div>
            </div>
          </div>
          <div class="present-item research item-3">
            <div class="present-icon"></div>
            <div class="present-title">学术科研</div>
            <div class="present-line"></div>
            <div class="present-text">认证医师打通随诊、会诊等各个关键环节<br>多个科研及认证执业医师场景服务</div>
            <div class="btn-box  btn-4">
              <div class="present-btn-box ">
                <div class="present-btn">精准诊断</div>
                <div class="present-btn">随诊</div>
              </div>
              <div class="present-btn-box ">
                <div class="present-btn">会诊</div>
                <div class="present-btn">更多功能</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- app宣传图 -->
    <div class="app-img">
      <div class="center-box">
        <div class="first-title">精准诊断</div>
        <div class="second-text">神经内科医师临床的智能诊断助手</div>
        <div class="img-box">
          <div class="side-img left-img">
            <div class="line-box left-line">
              <div class="tilted-line"></div>
              <div class="present-line">
                <div class="present-text">
                  <div class="title">精准诊断</div>
                  <div class="text">多病种的智能快速诊断<br>专业的诊断信息<br>详细的检查数据</div>
                </div>
              </div>
            </div>
          </div>
          <div class="center-img"></div>
          <div class="side-img right-img">
            <div class="line-box right-line">
              <div class="tilted-line"></div>
              <div class="present-line">
                <div class="present-text">
                  <div class="title">诊断结果</div>
                  <div class="text">平均80%的诊断准确率<br>结果建议全面（检查建<br>议、治疗建议）</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="app-card">
      <div class="center-box">
        <div class="card-box">
          <div class="card-model card-1">
            <div class="title">病例收集</div>
            <div class="line"></div>
            <div class="text">Lorem ipsum dolor sit amet, con sectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo, Proin sodales pulinar sic tempor.</div>
          </div>
          <div class="card-model card-2">
            <div class="title">样本提取</div>
            <div class="line"></div>
            <div class="text">Lorem ipsum dolor sit amet, con sectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo, Proin sodales pulinar sic tempor.</div>
          </div>
          <div class="card-model card-3">
            <div class="title">机器学习</div>
            <div class="line"></div>
            <div class="text">使用神经内科大量临床真实诊疗数据进行机器学习,在学习中改善具体病症的算法,提高算法的精准度,并持续进行数据模型调优和算法性能调优。</div>
          </div>
          <div class="card-model card-4">
            <div class="title">诊断模型</div>
            <div class="line"></div>
            <div class="text">根据患者提供的主要症状,伴随症状、生命体征及检验检查结果,遵循医学方法论,以临床医学知识及临床决策引擎为核心的诊断模型。</div>
          </div>
        </div>
      </div>
    </div> -->
    <Myfooter></Myfooter>
  </div>
</template>
<script>
import Myfooter from '../components/footer.vue'
  export default {
    name: 'product',
    data(){
      return {
        boxTopList: [],
        state: false,
      }
    },
    components: {
      Myfooter,
    },
    computed: {
      newContent() {
        return this.$store.getters.getNewContent
      },
    },
    created(){
      // this.getState();
    },
    methods: {
      handleScroll: function(){
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let routerName =  this.$route.name;
        if(routerName == 'product'){
          var boxList = document.getElementById('product').childNodes;
          var boxTopList = this.boxTopList
          var a = 0;
          for (let i = 0; i < boxTopList.length; i++) {
            let model = boxList[i];
            let modelBottom = model.getBoundingClientRect().bottom;
            if(boxTopList[i] < scrollTop  && modelBottom >= 120){
              model.classList.add('active');
            }
          }
        }
      },
    },
    mounted () {
      var topList = [];
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      var boxList = document.getElementById('product').childNodes || [];
      for (let i = 0; i < boxList.length ; i++) {
        let offsetHeight = boxList[i].offsetHeight * 0.4;
        topList.push(boxList[i].offsetTop - (windowHeight -offsetHeight));
      }
      this.boxTopList = topList;
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll();
    },
  }
</script>
<style lang="less" scoped>
  @import '../assets/less/product.less';
</style>