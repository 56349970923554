import { render, staticRenderFns } from "./home.vue?vue&type=template&id=6051f30e&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&id=6051f30e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6051f30e",
  null
  
)

component.options.__file = "home.vue"
export default component.exports