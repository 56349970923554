<template>
  <div class="center-box list-box">
    <div class="list-header">
      <div class="title">企业动态<span class="text">ENTERPRIS DYNAMIC</span></div>
    </div>
    <div id="new-list-box" ref="reference">
      <div class="list-model-box" v-for="(item) in listItem" :key="item.id">
        <div class="list-model" @click="toNewDetails(item.id)">
          <div class="img-box">
            <img :src="item.image" alt="">
          </div>
          <div class="news-content">
            <div class="news-title">{{item.title}}</div>
            <div class="news-text-box" ref="textbox" :class="exceedHeight == 0 ? 'text' : ''">
              <div class="news-text" ref="text" v-html="item.content">
                <!-- {{}} -->
              </div>
            </div>
            <div class="news-time-box">
              <div class="new-more">{{item.createTime}}</div>
              <div class="new-more">阅读全文>></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-page">
      <div class="page-nub">当前为第{{pageIndex}}页 共{{pages}}页</div>
      <ul class="page-btn-box">
        <div class="page-btn" @click="switchPageStr('first')" :class="pageIndex == 1 ? 'hide': ''">首页</div>
        <li class="page-btn" @click="switchPageStr('prev')">上一页</li>
        <li class="page-btn" v-for="(pageNub, index) in pageList" :key="index" @click="switchPageNub(pageNub)" :class="pageIndex == pageNub ? 'clickColoe' : ''">{{
          pageNub }}</li>
        <!-- <div class="page-btn">2</div>
      <div class="page-btn">3</div>
      <div class="page-btn">4</div>
      <div class="page-btn">5</div> -->
        <li class="page-btn" @click="switchPageStr('next')">下一页</li>
        <li class="page-btn" @click="switchPageStr('last')" :class="pages <=  pageNubSize? 'hide': ''">末页</li>
      </ul>
      <div class="page-size">
        <input type="text" class="size-input" ref="goPageNub" @keyup.enter="goPage()">
        <div class="size-input go" @click="goPage()">GO</div>
      </div>
    </div>
    <div class="prop-text" :class="goPageMsg">共{{pages}}页，请重新输入</div>
  </div>
</template>
<script>
  import {
    newsList
  } from '../../public/newDetails.js';
  export default {
    name: 'newlist',
    data() {
      return {
        exceedHeight: 1,
        pageList: [], // 页码数组
        pageIndex: 1, // 当前页
        pageNubSize: 5, // 每组分页数
        pages: 0, // 总页数
        pageNub: 4, // 每页显示数量
        msgList: [], // 分页信息
        listItem: [],
        goPageMsg: '',
      }
    },
    computed: {
      routerName() {
        return this.$store.getters.getRouterName
      },
    },
    watch: {
      pageIndex(newVal, oldVal) {
        // 向后翻页
        if (newVal >= this.pageList[this.pageList.length - 1] && this.pages > this.pageNubSize) {
          this.nextPage();
        }
        // 向前翻页
        else if (newVal <= this.pageList[0] && newVal >= 1 && this.pages > this.pageNubSize) {
          this.prevPage();
        }
        this.listItem = this.msgList[newVal];
      },
      listItem() {
        window.scrollTo({
          top: 0,
        });
      }
    },
    created() {
      this.getData();
    },
    methods: {
      // 初始化数据
      getData() {
        // console.log(newsList);
        // getNewMsg(this.pageIndex, this.pageNub).then((result) => {
          let data = newsList,
              page = 1,
              list = [];
          this.pages = data.length % this.pageNub == 0 ? data.length / this.pageNub : parseInt(data.length / this.pageNub) + 1;
          for (let i = 0; i < data.length; i++) {
            list.push(data[i]);
            if ((i + 1) % this.pageNub == 0) {
              this.msgList[page] = list;
              page++;
              list = [];
            }
          }
          if (list.length > 0) {
            this.msgList[page] = list;
          }
          this.listItem = this.msgList['1'];
          for (let i = 1; i <= this.pages && i <= this.pageNubSize; i++) {
            this.pageList.push(i);
          }
        // }).catch((err) => {});
      },
      boxHeight() {
        let boxHeight = this.$refs.textbox.offsetHeight,
          textHeight = this.$refs.text.offsetHeight
        if (boxHeight < textHeight) {
          this.exceedHeight = 0
        } else {
          this.exceedHeight = 1
        }
      },
      // 向下翻页计算
      nextPage() {
        let newList = [];
        if (this.pageIndex + this.pageNubSize >= this.pages) {
          let cutSize = this.pages - this.pageNubSize;
          for (let i = this.pageIndex - 1; i > cutSize && i >= cutSize; i--) {
            newList.push(i);
          }
          newList = newList.reverse();
        }
        let pagenub = this.pageNubSize + this.pageIndex;
        for (let i = this.pageIndex; i < pagenub && i <= this.pages; i++) {
          newList.push(i);
        }
        this.pageList = newList;
      },
      // 向上翻页计算
      prevPage() {
        let newList = [];
        let pagenub = this.pageIndex - this.pageNubSize;
        if (this.pageIndex < this.pageNubSize && this.pageIndex < this.pages) {
          for (let i = this.pageIndex + 1; i <= this.pageNubSize; i++) {
            newList.push(i);
          }
        }
        newList = newList.reverse()
        for (let i = this.pageIndex; i > pagenub && i >= 1; i--) {
          // console.log(i);
          newList.push(i);
        }
        newList = newList.reverse()
        this.pageList = newList;
      },
      // 页码数字翻页
      switchPageNub(pageIndex) {
        this.pageIndex = pageIndex;
      },
      // 上一页 下一页 末页
      switchPageStr(type) {
        let newIndex = this.pageIndex;
        // console.log(this.$refs.text);
        switch (type) {
          case 'next':
            newIndex += 1;
            this.pageIndex = newIndex <= this.pages ? newIndex : this.pageIndex;
            break;
          case 'prev':
            newIndex -= 1;
            this.pageIndex = newIndex >= 1 ? newIndex : this.pageIndex;
            break;
          case 'last':
            this.pageIndex = this.pages;
            break;
          case 'first':
            this.pageIndex = 1;
            break;
          default:
            break;
        }
      },
      // 跳页
      goPage() {
        let goPageNub = this.$refs.goPageNub.value;
        goPageNub = parseInt(goPageNub);
        if (goPageNub > this.pages || goPageNub < 1 ) {
          this.goPageMsg = 'no-hide';
          var timer = setTimeout(() => {
            this.goPageMsg = '';
          }, 5000);
        }
        if (goPageNub <= this.pages) {
          this.goPageMsg = '';
          this.pageIndex = goPageNub;
        }
        this.$refs.goPageNub.value = '';
      },
      // 资讯详情
      toNewDetails(id) {
        let routes = this.$router.resolve({
          path: 'news/newDetails',
          query: {
            'id': id
          }
        })
        window.open(routes.href, '_blank');
      }
    },
    mounted() {
    }
  }
</script>
<style lang="less">
  @import '../assets/less/newList.less';
</style>