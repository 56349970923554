<template>
  <div class="content-box">
    <div class="banner-box">
      <div class="center-box">
        <div class="text-box">
          <div class="title">关于企业<span class="next-icon"></span></div>
        <div class="text">ABOUT THE ENTERPRISE</div>
        <div class="line"></div>
        </div>
        
      </div>
    </div>
    <router-view/>
    <Myfooter></Myfooter>
  </div>
</template>
<script>
import Myfooter from '../components/footer.vue'
  export default {
    name: 'news',
    components: {
      Myfooter,
    },
  }
</script>
<style lang="less" scoped>
  @import '../assets/less/news.less';
</style>