<template>
  <div  class="content-box loadImg" id="home">
    <div class="banner-box">
      <div class="center-box">
        <div class="text-box">
          <div class="title">顶级人工智能医疗运营服务</div>
          <div class="text">以“助力医生诊疗和提升居民健康”为己任</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <!-- 医学大脑APP介绍 -->
    <div class="present-box" >
      <div class="center-box">
        <div class="first-title">领创医学大脑APP</div>
        <div class="second-text">神经内科领域智能辅助诊疗系统</div>
        <div class="present-text">
          医学大脑利用云平台，人工智能等科技手段，遵循医学方法论，以临床医学知识库及临床决策引擎、人工智能算法平台为核心的快速接入大健康诊疗平台，根据患者提供的主要症状，伴随症状、生命体征及检验检查结果，在移动端和PC端为基层医生提供在线医疗过程的辅助诊疗服务，目前已上线神经内科脑膜炎、眩晕疾病，头痛、癫痫、脑梗、周围神经病等更多疾病接入中。
        </div>
      </div>
    </div>
    <!-- 产品介绍下载 -->
    <div class="download-box">
      <div class="center-box">
        <div class="first-title">全球首个神经内科领域<br>智能辅助诊疗系统</div>
        <div class="second-text">通过互联网创新，打造神经内科医生辅助智能诊疗系统，<br>为其在临床工作之余，提供便捷、专业的辅助诊断服务。</div>
        <div class="download-button-box">
          <div class="button-item">扫码下载
            <div  class="download-img">
              <img src="../assets/image/product_QRcode.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="text-card-box">
          <div class="text-card item-1">
            <div class="card-title">精准专业</div>
            <div class="card-line"></div>
            <div class="card-text">专业的神经内科诊疗信息，<br>帮助临床诊断步骤化、标准化。</div>
          </div>
          <div class="text-card item-2">
            <div class="card-title">智能分析</div>
            <div class="card-line"></div>
            <div class="card-text">仅需平均3秒钟，<br>即出疾病结果、诊疗建议。
            </div>
          </div>
          <div class="text-card item-2">
            <div class="card-title">降低风险</div>
            <div class="card-line"></div>
            <div class="card-text">
              医学大脑帮助在医生<br>
              诊断之余，降低临床风险。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 慢病处方介绍 -->
<!--     <div class="present-box" >
      <div class="center-box">
        <div class="first-title">慢病处方</div>
        <div class="second-text"></div>
        <div class="present-text">
          为响应国务院正式下发《关于促进“互联网+医疗健康”发展的意见(国办发〔2018〕26号)》(下称《意见》)，明确允许依托医疗机构发展互联网医院，支持探索医疗机构处方与药品零售信息共享，我司探索院外处方和第三方配送流程，形成在线问诊、电子处方、药品配送到家全闭环的慢病处方解决方案。
        </div>
      </div>
    </div> -->
    <!-- 慢病处方 -->
    <!-- <div class="download-box">
      <div class="center-box">
        <div class="first-title">慢病处方</div>
        <div class="second-text">打通在线问诊、处方、药品配送到家全流程</div>
        <div class="download-button-box">
          <div class="button-item">扫码关注
            <div  class="download-img">
              <img src="../assets/image/prescription_QRcode.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="text-card-box">
          <div class="text-card item-1">
            <div class="card-title">药品管理</div>
            <div class="card-line"></div>
            <div class="card-text">按照政策及时提供线上药品处方信息<br>体现线下药事服务的平台价值</div>
          </div>
          <div class="text-card item-2">
            <div class="card-title">配方管理</div>
            <div class="card-line"></div>
            <div class="card-text">合法合规的为药师调剂和开药<br/>进行有效的控制
            </div>
          </div>
          <div class="text-card item-2">
            <div class="card-title">患者执方</div>
            <div class="card-line"></div>
            <div class="card-text">患者使用电子处方自行选择药店购买<br>体现了医改医药分离的特点
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 企业介绍 -->
    <div class="present-box" >
      <div class="center-box">
        <div class="first-title">领创精准医疗</div>
        <div class="second-text">医疗行业与人工智能相结合</div>
        <div class="present-text">
          陕西领创精准医疗科技有限公司成立于2017年，在国家医改、人工智能战略层面，进一步落实“将医疗行业与人工智能技术相结合”<br/>背景下的顶级人工智能医疗运营服务商。
        </div>
        <div class="present-text">
          公司始终坚持以“打造人工智能医疗新模式”为发展宗旨，以“助力医生诊疗和提升居民健康”为己任，积极实践国家“人工智能”战略<br/>
          计划，实施医疗大数据发展行动，加强新一代人工智能研发应用，搭建了“领创医学大脑”大健康平台。 
          
        </div>
        <div class="present-text">
          公司采取市场化运作方式，发展智能产业，拓展智能生活。运用新技术、新业态、新模式，大力改造提升传统产业，为移动医疗服务<br/>
          产业化升级及技术成果转化做出了行业创新。
        </div>
      </div>
    </div>
    <!-- 企业动态 -->
    <div class="newsList-box">
      <div class="center-box">
        <div class="first-title">企业动态</div>
        <div class="second-text">ENTERPRISE DYNAMICS</div>
        <div class="list-box" >
          <div class="list-item" v-for="(item,index) in newList" :key="item.id" :class="'item-'+index" @click="goNewDetails(item.id)">
            <div class="item-box">
              <img :src="item.image" class="new-img" alt="">
              <div class="content-box">
                <div class="home-new-title">{{item.title}}</div>
                <div class="home-new-content" >
                  {{item.present}}
                </div>
                <div class="home-time-more">
                  <div id="item-time">{{item.createTime}}</div>
                  <div>更多>></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link :to="{path:'/news'}">
          <div class="more-news">查看全部>></div>
        </router-link>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="teamwork-box">
      <div class="center-box">
        <div class="first-title">合作伙伴</div>
        <div class="second-text">COOPARETIVE PARTNER</div>
        <div class="img-box center-box">
          <div class="teamwork-img teamwork-1"></div>
          <div class="teamwork-img teamwork-2"></div>
          <div class="teamwork-img teamwork-3"></div>
          <div class="teamwork-img teamwork-4"></div>
          <div class="teamwork-img teamwork-5" ></div>
        </div>
      </div>
    </div>
    <Myfooter></Myfooter>
  </div>
  
</template>
<script>
import Myfooter from '../components/footer.vue'
import {
  newsList
} from '../../public/newDetails.js';
  export default {
    name: 'home',
    data(){
      return {
        boxTopList: [],
        newList: [],
        state: false,
      }
    },
    components: {
      Myfooter,
    },
    created(){
      this.getData();
      // this.getState();
      // this.getLoad();
    },
    computed: {
      routerName() {
        return this.$store.getters.getRouterName;
      },
    },
    methods: {
      // 初始化数据
      getData() {
        let data = newsList,
            list = [];
        for (let i = 0; i < 3; i++) {
          list.push(data[i]);
        }
        this.newList = list;
      },
      goNewDetails(id){
        let routes = this.$router.resolve({
          path: 'news/newDetails',
          query: {
            'id': id
          }
        })
        window.open(routes.href, '_blank');
      },
      homeHandleScroll: function(){
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let routerName = this.$route.name;
        // console.log(this.routerName);
        // console.log(this.$route.name);
        if(routerName == 'home'){
          var boxList = document.getElementById('home').childNodes || [];
          // var boxList = this.$refs.box.children;
          var boxTopList = this.boxTopList;
          for (let i = 0; i < boxTopList.length; i++) {
            let model = boxList[i];
            // 获取元素底部距离视窗顶部的距离
            let modelBottom = model.getBoundingClientRect().bottom;
            // console.log(modelBottom);
            // console.log(scrollTop);
            if(boxTopList[i] < scrollTop  && modelBottom >= 160){
              model.classList.add('active');
            }
          }
        }
      },
    },
    mounted () {
      var topList = [];
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      var boxList = document.getElementById('home').childNodes || [];
      for (let i = 0; i < boxList.length ; i++) {
        let offsetHeight = boxList[i].offsetHeight * 0.5;
        topList.push(boxList[i].offsetTop - (windowHeight -offsetHeight));
      }
      this.boxTopList = topList;
      window.addEventListener('scroll', this.homeHandleScroll); 
      this.homeHandleScroll();
      
    },
  }
</script>
<style lang="less" scoped>
  @import '../assets/less/home.less';
</style>