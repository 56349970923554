<template>
  <div id="app" >
    <div class="loading-box" :class="loadHide">
      <div class="top-loading">
        <img class="top-inner" src="./assets/image/loading_top.jpg" />
      </div>
      <div class="bottom-loading">
        <img class="bottom-inner" src="./assets/image/loading_bottom.jpg"/>
      </div>
      
    </div>
    <Myheader></Myheader>
    <!-- <div :class="loadHide == ''? 'app-box': 'no-hide'"> -->
      <router-view />
    <!-- </div> -->
    <div class="goBav-box" :class="navClass">
      <div class="goTop"  @click="goTop"></div>
      <div class="goList goNew" :class="routerName == 'newDetails' ? '' : 'hide'" @click="goNewLis">回列表</div>
    </div>
    
  </div>
</template>
<script>
import Myheader from './components/header.vue';
import Myfooter from './components/footer.vue';
export default {
  name: 'App',
  data () {
    return {
      routerName: this.$route.name,
      navClass: '',
      loadHide: '',
      gifImg: require('./assets/image/loading.gif')
    }
  },
  components: {
    Myheader,
    Myfooter,
  },
  created(){
    var that = this;
    that.getState();
  },
  watch:{
    $route(to,from){
      var that =this;
      
      // this.loadHide = '';
      this.routerName = to.name;
      
      this.$store.dispatch('setRouterName', to.name);
      // setTimeout(function(){
        that.getState();
      // },2000)
      if(to.name == 'newList'){
        window.scrollTo({ 
            top: 0, 
        });
      }
    }
  },
  methods: {
    // 获取页面加载状态
    getState(){
      var that = this;
        if(this.routerName !='newDetails'){
          setTimeout(() => {
            this.loadHide = 'go-back';
          }, 5000);
        }else{
          this.loadHide = 'go-back-now';
        }
        window.addEventListener('scroll', this.indexHandleScroll);
    },
    indexHandleScroll: function(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop >= 200){
        this.navClass = 'active';
      }else{
        this.navClass = '';
      }
    },
    // 回顶部
    goTop: function(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var topTime = 60;
      var speed = scrollTop / topTime;
      var goTopItem = function(){
        scrollTop -= speed;
        if(scrollTop >= 0){
          window.scrollTo({ 
            top: scrollTop, 
          });
        }else{
          clearInterval(timer)
        }
      }
      goTopItem()
      var timer = setInterval(() => {
        goTopItem();
      }, 1);
    },
    goNewLis: function(){
      this.$router.replace({
        path: '/news'
      })
    },
    
    // getNews: function(){
    //   let newCon = JSON.stringify(this.newContentLists);
    //   this.$store.dispatch('setNewContent', this.newContentLists);
    //   console.log(this.$store.getters.getNewContent);
    // },
  },
  mounted() {
    this.$store.dispatch('setRouterName', this.$route.name);
    if(this.$route.name == 'newList'){
      // console.log(0);
      window.scrollTo({ 
        top: 0, 
      });
    }
  }
}
</script>
<style lang="less">
@import './assets/less/index.less';
</style>