import Vue from 'vue'
import Router from 'vue-router'
import home from './views/home.vue'
import news from './views/news.vue'
import newList from './views/newList.vue'
import product from './views/product.vue'
// import prescription from './views/prescription.vue'
import newDetails from './views/newDetails.vue'
// import newDetails1 from './views/newDetails1.vue'

Vue.use(Router)

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   redirect: '/home'
    // },
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
      path: '/news',
      name: 'news',
      component: news,
      children: [{
        path: '/',
        name: 'newList',
        component: newList,
      },
      {
        path: '/news/newDetails',
          name: 'newDetails',
          component: newDetails,
      }]
    },
    {
      path: '/product',
      name: 'product',
      component: product
    },
    // {
    //   path: '/prescription',
    //   name: 'prescription',
    //   component: prescription
    // },
  ]
})
